import Header from "./components/Header/Header";
import AppContextProvider from "./context/AppContext";
import HeaderContextProvider from "./context/HeaderContext";
import { EntriesTypeEnum } from "./enum/entriesType-model";
import { getLocationPathName, setHotspotName } from "./services/DataContainer";
import Home from "./templates/Home/Home";
import PanoramaView from "./templates/Home/PanoramaView";
import { getHeaderAndFooterHeight } from "./utils/utils";

function App() {
  addEventListener("popstate", () => {
    setHotspotName(getLocationPathName());
  });

  const hasBrowserRefresh: any = performance.getEntriesByType(EntriesTypeEnum.NAVIGATION)[0];

  if (hasBrowserRefresh.type === EntriesTypeEnum.RELOAD) {
    history.replaceState("", "", `${window.location.pathname}`);
  }
  return (
    <div>
      <HeaderContextProvider>
        <Header />
      </HeaderContextProvider>
      <AppContextProvider>
        <Home />
        <PanoramaView />
      </AppContextProvider>
    </div>
  );
}

export default App;
