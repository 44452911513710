import { createRef, useContext, useEffect, useRef, useState } from "react";
import { S360ViewProps } from "./360ViewProps";
import ViewDescription from "./ViewDescription/ViewDescription";
import DataContainer, { $isProductLoaded, getLocationPathName} from "../../services/DataContainer";
import {
  HotspotPositionModel,
  Pannellum360,
  PanormaViewModel
} from "../../interface/panorma.model";
import { Pannellum } from "pannellum-react";
import { Icons } from "../../constants/Icons/Icons";
import { AppContainerContext } from "../../context/AppContext";
import { Asset, AvailableVariations, ComponentModel } from "../../interface/component.model";
import { getHeaderAndFooterHeight, getHeaderHeight, getOffsetTop, isMobile, isTablet } from "../../utils/utils";
import { QueryParamsEnum } from "../../enum/queryParams.model";
import classnames from "classnames";
import { PanoramaEnum, S360ViewHotspotsKeyValues } from "../../enum/panorama.model";
import { SectionName } from "../../enum/sectionName.model";
import { Subject } from "rxjs";
import Loader from "../Loader/Loader";

const S360View = ({ image_url, hotspotName }: S360ViewProps) => {
  const { product } = useContext(AppContainerContext);
  const [canImageRotate, setCanImageRotate] = useState(true);
  const { sections }: any = DataContainer.mApplicationData;
  const [areSectionsLoaded, setSectionsLoaded] = useState(false);
  const [markets, setMarkets] = useState<PanormaViewModel>();
  const orbit = useRef<Pannellum360>(null);
  const [hotspotPositions, setHotspostPositions] = useState<HotspotPositionModel[]>([]);
  const [showDescription, setShowDescription] = useState<boolean>(false);
  const [interaction, setInteraction] = useState<boolean>(false);
  const [showRotateIcon, setShowRotateIcon] = useState<boolean>(false);
  const [showHotspotPanel, setShowHotspotPanel] = useState(false);
  const [hotspotKey, setHotspotKey] = useState("");
  const [switchContent, setSwitchContent] = useState(false);
  const $isHotspotActive = new Subject<boolean>();
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [isLoading, setIsLoading] = useState(true);
  const babylon: HTMLDivElement = document.querySelector(".babylon")!;


  const loaderRef: React.RefObject<HTMLDivElement> = createRef();

  const detectInteraction = (): boolean => {
    document.getElementById("panorama")?.addEventListener("onkeydown", () => {
      setInteraction(true);
    });

    document.getElementById("panorama")?.addEventListener("click", () => {
      setInteraction(true);
    });

    document.getElementById("panorama")?.addEventListener("touchstart", () => {
      setInteraction(true);
      setCanImageRotate(false);
    });

    return interaction;
  };

  useEffect(() => {
    detectInteraction()
      ? setShowRotateIcon(false)
      : setTimeout(() => {
        setShowRotateIcon(true);
      }, 5000);
    clearInterval();
  }, [interaction, showRotateIcon]);

  useEffect(() => {
    const pathname: any = getLocationPathName();
    if(pathname?.length > 2){
      babylon.classList.remove("block");
      babylon.classList.add("hidden");
    } else {
      babylon.classList.remove("hidden");
      babylon.classList.add("block");
    }
    $isProductLoaded.subscribe((isReady: boolean) => setSectionsLoaded(isReady));
  }, []);

  useEffect(() => {
    product?.component_groups[0].components?.map((component: ComponentModel) => {
      if (component.name === hotspotName) {
        const Variants: AvailableVariations[] = getVariantOfSelectedComponent(component.id);
        Variants.map((variant: AvailableVariations) => {
          variant.assets.map((asset: Asset) => {
            const selectedVariant: AvailableVariations = filterVariants(asset.variation_id);
            positionTheHotspots({
              key: selectedVariant.key,
              x: asset.attribute_values[0].value,
              y: asset.attribute_values[1].value
            });
          });
        });
      }
    });
  }, [product]);

  const positionTheHotspots = (hotspotPosition: HotspotPositionModel) => {
    setHotspostPositions((prev: HotspotPositionModel[]) => [
      ...prev,
      {
        key: hotspotPosition.key,
        x: hotspotPosition.x,
        y: hotspotPosition.y
      }
    ]);
  };

  const getVariantOfSelectedComponent = (componentID: number) => {
    return DataContainer.findComponentVariations(componentID);
  };

  const filterVariants = (variantID: number) => {
    return DataContainer.getAllVariations().filter(
      (variant: AvailableVariations) => variant.id === variantID
    )[0];
  };

  useEffect(() => {
    if (areSectionsLoaded) {
      sections.map((item: { section_name: string; key_values: PanormaViewModel }) => {
        if (item.section_name === hotspotName) {
          if (item.key_values.description_second) {
            setMarkets({
              title: item.key_values.title,
              description: item.key_values.description,
              link_title: item.key_values.link_title,
              link_url: item.key_values.link_url,
              description_second: item.key_values.description_second,
              link_title_second: item.key_values.link_title_second,
              link_url_second: item.key_values.link_url_second
            });
          } else {
            setMarkets({
              title: item.key_values.title,
              description: item.key_values.description,
              link_title: item.key_values.link_title,
              link_url: item.key_values.link_url
            });
          }
        }
      });
    }
  }, [areSectionsLoaded]);

  const hotspotContent = (sectionName: string, keyValue: string) => {
    let value = "";
    sections?.map(({ section_name, key_values }: { section_name: string; key_values: any }) => {
      if (section_name === sectionName) {
        value = key_values[keyValue];
      }
    });
    return value;
  };

  const rotateCamera = () => {
    isMobile()
      ? orbit.current && orbit.current.getViewer().startAutoRotate(-275)
      : orbit.current && orbit.current.getViewer().startAutoRotate(-75);
    setTimeout(() => {
      setInteraction(true);
      setShowRotateIcon(false);
      if(canImageRotate){
        orbit.current && orbit.current.getViewer().startAutoRotate(-2);
      } else{
        orbit.current && orbit.current.getViewer().stopAutoRotate();
      }
    }, 750);
  };

  window.addEventListener("resize", () => setInnerHeight(window.innerHeight));

  const toggleOrbit = () => {
    setCanImageRotate((prev: boolean) => !prev);
    if (canImageRotate) {
      orbit.current && orbit.current.getViewer().stopAutoRotate();
    } else {
      orbit.current && orbit.current.getViewer().startAutoRotate(-2);
    }
  };

  const onHotspotClick = (key: string) => {
    $isHotspotActive.next(true);
    setShowHotspotPanel((current: boolean) => !current);
    setHotspotKey(key);
  };

  useEffect(() => {
    if (hotspotKey) {
      if (showDescription) {
        setShowDescription(false);
        setTimeout(() => {
          handleActiveHostpotCase();
        }, 250);
      } else {
        handleActiveHostpotCase();
      }
    }
  }, [showHotspotPanel]);

  const handleActiveHostpotCase = () => {
    const allHotspotElements = document.querySelectorAll(".in");
    allHotspotElements.forEach((hotspotElement: any) => {
      if (hotspotElement.closest(`.hotspot-${hotspotKey}`)) {
        if (
          !hotspotElement?.className.includes("open-background") &&
          !hotspotElement?.className.includes("close-background")
        ) {
          hotspotElement?.classList.add("open-background");
          setShowDescription(true);
        } else if (hotspotElement?.className.includes("open-background")) {
          hotspotElement?.classList.remove("open-background");
          hotspotElement?.classList.add("close-background");
          setShowDescription(false);
          $isHotspotActive.next(false);
        } else {
          hotspotElement?.classList.remove("close-background");
          hotspotElement?.classList.add("open-background");
          setShowDescription(true);
        }
      } else {
        hotspotElement?.classList.remove("open-background");
        hotspotElement?.classList.add("close-background");
      }
    });
  };

  const handlePanoramaLoad = () => {
    setIsLoading(false); 
    loaderRef.current && loaderRef.current.remove();
  };
  
  $isHotspotActive.subscribe((isActive: boolean) => setSwitchContent(isActive));

  return (
    <>
      <div
        className={classnames(
          "absolute w-full h-full left-0 parent-panellum-element overflow-hidden",
        )}>
        <div className="w-full h-full">
          {isLoading && <Loader text="Loading 360 image" ref={loaderRef} />}
          <Pannellum
            ref={orbit}
            id="panorama"
            width="100%"
            height="100%"
            image={`${image_url}?x-request=html`}
            onLoad={() => handlePanoramaLoad()}
            onError={() => handlePanoramaLoad()}
            autoLoad
            maxHfov={!isMobile() ? 120 : 60}
            minHfov={!isMobile() ? 40 : 20}
            onTouchStart={() => setCanImageRotate(false)}
            onMouseup={() => setCanImageRotate(false)}
            maxPitch={40}
            hotspotDebug={window.location.search === QueryParamsEnum.GET_POSITION ? true : false}
            autoRotate={-2}>
            {hotspotPositions &&
      hotspotPositions.map((hotspot: HotspotPositionModel, index: number) => (
        <Pannellum.Hotspot
          key={index + 1}
          type={hotspot.key.includes("video") ? "info" : "custom"}
          pitch={hotspot.y}
          yaw={hotspot.x}
          cssClass={`hotspot-${hotspot.key}`}
          handleClick={(ev: Event, args: HotspotPositionModel) => {
            onHotspotClick(args.key);
          }}
          handleClickArg={{ key: hotspot.key, x: hotspot.x, y: hotspot.y }}
          hga
          URL={DataContainer.sectionKey(
            PanoramaEnum.HOTSPOT_VIDEO_LINK_VOLVO_FH_ELECTRIC,
            PanoramaEnum.HOTSPOT_KEY_VIDEO_LINK
          )}
        />
      ))}
          </Pannellum>
          <div
            className={classnames(
              "absolute z-[51] left-7 cursor-pointer ease-in-out duration-300",
              {
                "bottom-[130px]": !showDescription
              },
              { "bottom-[280px]": showDescription },
              { "bottom-[365px]" : showDescription && isTablet()}
            )}
            onClick={toggleOrbit}>
            {canImageRotate ? Icons.PanoramaPlay : Icons.PanoramaPause}
          </div>
          <div className="absolute z-50 flex justify-center md:justify-end w-full md:w-auto bottom-[17vh] md:right-8 cursor-drag md:bottom-[45vh]">
            <div className="cursor-pointer" onTouchStart={rotateCamera} onClick={rotateCamera}>
              {!showRotateIcon
                ? ""
                : isMobile()
                  ? Icons.SmallRotateCameraIcon
                  : Icons.BigRotateCameraIcon}
            </div>
          </div>
        </div>
        <div style={{top: `${getHeaderHeight()}px`, minHeight: `calc(100vh - ${getHeaderAndFooterHeight}px)`}}>
          <ViewDescription
            innerHeight={innerHeight}
            showDescription={showDescription}
            setShowDescription={setShowDescription}
            headline={
              switchContent
                ? hotspotContent(hotspotKey, S360ViewHotspotsKeyValues.TITLE)
                : markets?.title
            }
            content={
              switchContent
                ? hotspotContent(hotspotKey, S360ViewHotspotsKeyValues.DESCRIPTION)
                : markets?.description
            }
            content_second={
              switchContent
                ? hotspotContent(hotspotKey, S360ViewHotspotsKeyValues.DESCRIPTION_SECOND)
                : markets?.description_second
            }
            link_title={
              switchContent
                ? hotspotContent(hotspotKey, S360ViewHotspotsKeyValues.LINK_TITLE)
                : markets?.link_title
            }
            link_title_second={
              switchContent
                ? hotspotContent(hotspotKey, S360ViewHotspotsKeyValues.LINK_TITLE_SECOND)
                : markets?.link_title_second
            }
            link_url={
              switchContent
                ? hotspotContent(hotspotKey, S360ViewHotspotsKeyValues.LINK_URL)
                : markets?.link_url
            }
            link_url_second={
              switchContent
                ? hotspotContent(hotspotKey, S360ViewHotspotsKeyValues.LINK_URL_SECOND)
                : markets?.link_url_second
            }
            imageDescription={{
              top: DataContainer.sectionKey(
                SectionName.ACTION_KEYWORD,
                PanoramaEnum.ACTION_NEXT_HOTSPOT_PANORAMAVIEW
              )
            }}
          />
        </div>
      </div>
    </>
    
  );
};

export default S360View;
