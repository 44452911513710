import axios from "axios";
import DataContainer from "./DataContainer";

export function getLocales() {
  return axios.get("https://one-app.onecx.rapidimages.com/api/v1/locales", {
    headers: {
      "X-Header-ApiKey": DataContainer._apiKey,
      "X-Header-AppId": DataContainer._applicationId
    }
  });
}
