import classnames from "classnames";
import { forwardRef } from "react";
import { Icons } from "../../constants/Icons/Icons";
import { LoaderProps } from "./types";
// eslint-disable-next-line react/display-name
const Loader = forwardRef(({ text }: LoaderProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  return (
    <div
      ref={ref}
      className={classnames("w-full h-full absolute top-0 flex flex-col items-center justify-center bg-white",{"z-[70]" : !text}, {" z-[51]": text})}>
      {text ? (
        <div className="loader-text">{text}</div>
      ) : (
        <div className="loader-text">{Icons.LoaderText}</div>
      )}
      <div className="mt-5 relative rounded-full flex items-center justify-center w-20 h-20 shadow-light-blue">
        <div className={classnames("w-20 h-20 rounded-full flex items-center justify-center circle-loader blur-[0.5px]", {"loading-loader" : text})}></div>
        <div
          className="rounded-full w-[60px] h-[60px] bg-white arrow-done flex items-center justify-center 
                        absolute z-10 translate-x-0 translate-y-0 shadow-light-blue done-loading"></div>
      </div>
    </div>
  );
});

export default Loader;
