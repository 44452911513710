export const isMobile = () => {
  if (window.innerWidth < 768) {
    return true;
  }
  return false;
};

export const isTablet = () => {
  if (window.innerWidth < 1200) {
    return true;
  }
  return false;
};

export const isSmallScreen = () => {
  return window.innerWidth < 540;
};
export const formatRootUrl = (url: string | undefined) => (
  !url ? "" : url?.endsWith("/") ? url.slice(0, -1) : url
);

export const getOffsetTop = (elem: any) => {
  let distance = 0;
  if (elem.offsetParent) {
    do {
      distance += elem.offsetTop;
      elem = elem.offsetParent;
    } while (elem);
  }
  return distance < 0 ? 0 : distance;
};

export const getHeaderHeight = () => {
  const headerHeight = 72;
  return headerHeight;
};

export const getFooterHeight = () => {
  const footerHeight = window.innerWidth > 1240 ? 58 : 92;
  return footerHeight;
};

export const getHeaderAndFooterHeight = () => {
  return getHeaderHeight() + getFooterHeight();
};