import {
  ArcRotateCamera,
  Vector3,
  HemisphericLight,
  SceneLoader,
  Color3,
  Scene,
  ISceneLoaderProgressEvent,
  Sprite,
  SpriteManager,
  ActionManager,
  ExecuteCodeAction,
  Color4,
  CubeTexture,
  PointerEventTypes,
  Matrix,
  Viewport
} from "@babylonjs/core";
import React, { createRef, useContext, useRef } from "react";
import Loader from "../Loader/Loader";
import { formatRootUrl, isTablet, isSmallScreen, isMobile, getOffsetTop, getHeaderAndFooterHeight, getFooterHeight, getHeaderHeight } from "../../utils/utils";
import { AppContainerContext } from "../../context/AppContext";
import { HotspotModel } from "../../interface/hotspots.model";
import DataContainer, { $playCameraText, setHotspotName } from "../../services/DataContainer";
import { Icons } from "../../constants/Icons/Icons";
import SceneComponent from "./SceneComponent";
import { SectionName } from "../../enum/sectionName.model";
import { PanoramaEnum } from "../../enum/panorama.model";
import { QueryParamsEnum } from "../../enum/queryParams.model";
import { ComponentEnum } from "../../enum/componet.model";
import classnames from "classnames";

const Babylon = () => {
  const { hotspots, isProductLoading } = useContext(AppContainerContext);
  const loaderRef: React.RefObject<HTMLDivElement> = createRef();
  let light: HemisphericLight;
  const wrapperOfHotspotElementOnScene: any = useRef();
  const panoramaViewElement: HTMLDivElement = document.querySelector(".parent-panellum-element")!;
  const cameraButton: HTMLDivElement = document.querySelector(".camera-button")!;
  const rootUrl = formatRootUrl(process.env.REACT_APP_ROOT_URL);
  const playCameraText = useRef<any>(null);

  const canvasTop = getOffsetTop(document.getElementById("virtualShowroomApp"));
  const onSceneReady = (scene: Scene) => {
    if(cameraButton) cameraButton.classList.add("camera-button");
    const { sections }: any = DataContainer.mApplicationData;
    if (!isProductLoading) {
      scene.clearColor = new Color4(0, 0, 0, 0);
      const camera: ArcRotateCamera = new ArcRotateCamera(
        "camera",
        1.8,
        0.8,
        isTablet() ? 100 : 65,
        new Vector3(0, -5, 0),
        scene
      );
      const canvas = scene.getEngine().getRenderingCanvas();
      camera.attachControl(canvas, true);
      camera.upperRadiusLimit = isTablet() ? 145 : 65;
      camera.lowerRadiusLimit = 25;
      camera.upperBetaLimit = 1;
      camera.useAutoRotationBehavior = true;
      const spriteManager = new SpriteManager(
        "spriteManager",
        `${rootUrl}/pulse_hotspot.png`,
        hotspots.length,
        350,
        scene
      );
      let shouldRotate = true;
      const autoRotationBehaviorElement: HTMLElement = document.querySelector(".orbit")!;
      if (autoRotationBehaviorElement) {
        autoRotationBehaviorElement.innerHTML = `${Icons?.Play}`;
        autoRotationBehaviorElement.onclick = function () {
          camera.useAutoRotationBehavior = !camera.useAutoRotationBehavior;
          if (!camera.autoRotationBehavior) {
            shouldRotate = false;
            autoRotationBehaviorElement.innerHTML = `${Icons?.Pause}`;
          } else {
            shouldRotate = true;
            autoRotationBehaviorElement.innerHTML = `${Icons?.Play}`;
          }
        };
      }
      spriteManager.isPickable = true;
      const previewDiv = document.createElement("div");
      if (previewDiv) previewDiv.style.width = "0px";
      previewDiv.style.height = "0px";
      const bubbleCircle = document.createElement("div");
      const previewText = document.createElement("div");

      const setPreviewData = (preview: { title: string; backgroundImage: string }) => {
        previewText.innerText = `${DataContainer.sectionKey(SectionName.ACTION_KEYWORD, "custom_3d_hotspot_text")} ${DataContainer.sectionKey(ComponentEnum.HOTSPOTS_NAME, preview.title)}`;
        previewDiv.style.backgroundImage = `url(${preview.backgroundImage})`;
      };
      const showPreview = (id: number, hotspotName: string, backgroundImageURL: string) => {
        setPreviewData({
          title: hotspotName,
          backgroundImage: backgroundImageURL
        });
        previewDiv.style.display = "flex";
        wrapperOfHotspotElementOnScene.current.style.display = "flex";
        wrapperOfHotspotElementOnScene.current.className = classnames("absolute justify-center flex-col items-center rounded-full bg-transparent text-white z-50 bg-cover animate-scaling origin-bottom w-full overflow-hidden cursor-pointer", {"origin-center" : isSmallScreen()});
        previewDiv.style.width = `${isSmallScreen() ? `${window.innerWidth + 50}px` : "280px"}`;
        previewDiv.style.height = `${isSmallScreen() ? `${window.innerWidth + 50}px` : "280px"}`;
        wrapperOfHotspotElementOnScene.current.style.width = `${isSmallScreen() ? `${window.innerWidth + 50}px` : "280px"}`;
        wrapperOfHotspotElementOnScene.current.style.height = `${isSmallScreen() ? `${window.innerWidth + 50}px` : "280px"}`;
        previewDiv.className = classnames("absolute justify-center flex-col items-center rounded-full bg-transparent text-white z-50 bg-cover animate-scaling origin-bottom h-auto w-full cursor-pointer", 
          {"absolute justify-center flex-col items-center rounded-full bg-transparent text-white w-full origin-center" : isSmallScreen()});
         
        previewText.style.display = "block";
        previewText.className = classnames("text-sm font-medium text-center m-auto absolute origin-bottom-right translate-y-0 animate-scaling animate-translate px-3 md:p-0 w-10/12 md:w-11/12", {"text-2xl" : isSmallScreen()});
        bubbleCircle.className = "rounded-full gradient-border border-blue mt-auto absolute z-50 bottom-0 cursor-pointer";
        bubbleCircle.innerHTML = Icons.InsideCircle;
        previewDiv.appendChild(previewText);
        previewDiv.appendChild(bubbleCircle);
        wrapperOfHotspotElementOnScene.current.appendChild(previewDiv);
        previewDiv.onclick = () => {
          history.pushState("", "", `/${hotspotName}`);
          if (panoramaViewElement) {
            panoramaViewElement.style.display = "block";
          }
          setHotspotName(hotspotName);
          hidePreview();
        };
        if(isTablet()){ 
          previewDiv.ontouchstart = (e) => {
            e.stopImmediatePropagation();
            e.preventDefault();
            history.pushState("", "", `/${hotspotName}`);
            if (panoramaViewElement) panoramaViewElement.style.display = "block";
            setHotspotName(hotspotName);
            hidePreview();
          };
        }
      };

      const hidePreview = () => {
        window.removeEventListener("mousemove", () => ({}));
        window.removeEventListener("touchstart", () => ({}));
        previewDiv.className = "animate-closing rounded-full origin-bottom justify-center flex-col items-center bg-transparent bg-cover";
        wrapperOfHotspotElementOnScene.className = "animate-closing rounded-full origin-bottom bg-cover justify-center flex-col items-center bg-transparent";
        previewText.className = "animate-closing origin-bottom animate-translate text-center m-auto";
        setTimeout(() => {
          previewDiv.style.display = "none";
          previewText.style.display = "none";
          wrapperOfHotspotElementOnScene.current.style.display = "none";
        }, 400);

      };

      document.getElementById("sidebar")?.addEventListener("click", () => {
        hidePreview();
      });

      const createHotspotInstance = (
        position: Vector3,
        id: number,
        hotspotName: string,
        backgroundImageURL: string
      ) => {
        const hotspotInstance = new Sprite("hotspot", spriteManager);
        hotspotInstance.position = position;
        hotspotInstance.size = 1;
        hotspotInstance.isPickable = true;
        hotspotInstance.playAnimation(0, 62, true, 0);
        hotspotInstance.actionManager = new ActionManager(scene);
        hotspotInstance.actionManager?.registerAction(
          new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, () => {
            const pos = Vector3.Project(position,            
              Matrix.Identity(),
              scene.getTransformMatrix(),
              new Viewport(0, 0, canvas?.width || 0, canvas?.height || 0)
            );
            camera.useAutoRotationBehavior = false;
            if(autoRotationBehaviorElement) autoRotationBehaviorElement.innerHTML = `${Icons?.Pause}`;
            if(isTablet() && !hotspotName.includes(ComponentEnum.OUT_LINK)){
              window.addEventListener(
                "touchstart",
                (e) => {
                  e.preventDefault();
                  e.stopImmediatePropagation();
                  previewDiv.style.display = "flex";
                  wrapperOfHotspotElementOnScene.current.style.display = "flex";
                  if (wrapperOfHotspotElementOnScene.current && !hotspotName.includes(ComponentEnum.OUT_LINK)) {
                    if(isSmallScreen()){
                      wrapperOfHotspotElementOnScene.current.style.top = "20%";
                      wrapperOfHotspotElementOnScene.current.style.left = "-25px";
                    } else {
                      wrapperOfHotspotElementOnScene.current.style.top = `${pos.y - 273}px`;
                      wrapperOfHotspotElementOnScene.current.style.left = `${pos.x - 140}px`;
                    }
                  }
                },
                { once: true, passive: false }
              );
            } else {

              window.addEventListener(
                "mousemove",
                (e) => {
                  e.preventDefault();
                  if (wrapperOfHotspotElementOnScene.current) {
                    wrapperOfHotspotElementOnScene.current.style.top = `${pos.y - 273}px`;
                    wrapperOfHotspotElementOnScene.current.style.left = `${pos.x - 140}px`;
                  }
                },
                { once: true }
              );
            }
            if (hotspotName.includes(ComponentEnum.OUT_LINK)) {
              window.addEventListener("click", () => {
                window.open(
                  DataContainer.getSingelSectionValue(sections, ComponentEnum.HOTSPOTS_NAME, hotspotName),
                  "_blank"
                );
              }, {once: true});
            } else {
              showPreview(id, hotspotName, backgroundImageURL);
            }
          })
        );
        hotspotInstance.actionManager?.registerAction(
          new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, function () {
            if(autoRotationBehaviorElement){
              if(shouldRotate){
                camera.useAutoRotationBehavior = true;
                autoRotationBehaviorElement.innerHTML = `${Icons?.Play}`;
              } else {
                autoRotationBehaviorElement.innerHTML = `${Icons?.Pause}`;
                camera.useAutoRotationBehavior = false;
              }
              hidePreview();
            }
          })
        );
        return hotspotInstance;
      };

      hotspots.forEach((hotspot: HotspotModel, index: number) => {
        createHotspotInstance(
          new Vector3(hotspot.position.x, hotspot.position.y, hotspot.position.z),
          index,
          hotspot.name,
          hotspot.asset_url
        );
      });
      const circleLoader = loaderRef?.current?.querySelector(".circle-loader") as HTMLElement;
      const doneLoading = loaderRef?.current?.querySelector(".done-loading") as HTMLElement;
      const loaderText = loaderRef?.current?.querySelector(".loader-text") as HTMLElement;
      SceneLoader.ShowLoadingScreen = false;
      light = new HemisphericLight("light", new Vector3(0, 1, 0), scene);
      SceneLoader.Append(
        `${rootUrl}/`,
        "volvo_virtual_showroom.babylon",
        scene,
        (scene: Scene) => {
          scene.activeCamera = camera;
          scene.clearColor = Color3.FromHexString("#c5c5c5").toColor4();
          const hdrTexture = CubeTexture.CreateFromPrefilteredData(`${rootUrl}/studio.env`, scene);
          scene.createDefaultSkybox(hdrTexture, true, 1);
          setTimeout(() => loaderRef.current && loaderRef.current.remove(), 1000);
        },
        (ev: ISceneLoaderProgressEvent) => {
          const progress = `${((ev.loaded * 100) / ev.total).toFixed()}`;
          if (loaderRef.current && ev.loaded > 0) {
            circleLoader.style.background = `linear-gradient(0deg, rgba(105, 192, 181, ${
              Number(progress) / 100
            })
            ${Number(progress) / 7}%, rgba(105, 192, 181, 0) ${Number(progress) * 2 + 10}%)`;
            if (doneLoading && Number(progress) === 100) {
              doneLoading.innerHTML = Icons.Done;
              loaderText.innerHTML = "";
            } else {
              doneLoading.innerHTML = "";
            }
          }
        }
      );
    }
    if(window.location.search === QueryParamsEnum.GET_POSITION) {
      scene.onPointerObservable.add((evt: any) => {
        if (evt.type === PointerEventTypes.POINTERPICK) {
          //console log is for showing the position of hotspot in 3D scene
          console.log(`X: ${evt.pickInfo.pickedPoint._x.toFixed(2)}, Y: ${evt.pickInfo.pickedPoint._y.toFixed(2)}, Z: ${evt.pickInfo.pickedPoint._z.toFixed(2)}`);
        }
      });
    }
  };

  $playCameraText.subscribe((res: string) => {
    if (playCameraText.current) {
      playCameraText.current.innerHTML = res;
    }
  });
  
  return (
    <div className="block babylon w-full h-full">
      <Loader ref={loaderRef} />
      <div className="w-full h-full">
        <SceneComponent
          antialias
          onSceneReady={onSceneReady}
          id="my-scene"
          className="w-full h-screen"
        />
      </div>
      <div className={classnames("relative z-[60]", { "text-sm font-medium text-center m-auto animate-scaling origin-bottom-right translate-y-0 animate-translate" : isSmallScreen()})} ref={wrapperOfHotspotElementOnScene}></div>
      <div className="flex items-center absolute w-max left-4 cursor-pointer camera-button">
        <span className="orbit"></span>
        <p className="pl-3 text-base font-normal" ref={playCameraText}>
          {DataContainer.sectionKey(
            SectionName.ACTION_KEYWORD,
            PanoramaEnum.ACTION_ROTATE_CAMERA_3D
          )}
        </p>
      </div>
    </div>
  );
};

export default Babylon;
