export enum PanoramaEnum {
    HOTSPOT_VIDEO_LINK_VOLVO_FH_ELECTRIC = "360_volvo_fh_electric_play_video",
    HOTSPOT_KEY_VIDEO_LINK = "video_link",
    ACTION_ROTATE_CAMERA_3D = "action_rotate_camera_3d",
    ACTION_NEXT_HOTSPOT_PANORAMAVIEW = "action_next_hotspot_panoramaview"
}

export enum S360ViewHotspotsKeyValues {
    TITLE = "title",
    DESCRIPTION = "description",
    DESCRIPTION_SECOND = "description_second",
    LINK_TITLE = "link_title",
    LINK_TITLE_SECOND = "link_title_second",
    LINK_URL = "link_url",
    LINK_URL_SECOND = "link_url_second"
}
