import classnames from "classnames";
import { useContext, useEffect, useRef, useState } from "react";
import { MarketsModel, SideBarProps } from "./SidebarProps";
import { useId } from "react";
import { Icons } from "../../constants/Icons/Icons";
import DataContainer, { setHotspotName, setPlayCameraText } from "../../services/DataContainer";
import { HeaderContainerContext } from "../../context/HeaderContext";
import { isMobile } from "../../utils/utils";
import { ComponentEnum } from "../../enum/componet.model";
import { getLocales } from "../../services/ApiService";
import { MarketsEnum } from "../../enum/markets.model";
// @ts-ignore Images are not known as modules
import enFlag from "../../assets/images/markets/en.png";
// @ts-ignore Images are not known as modules
import deFlag from "../../assets/images/markets/de.png";
// @ts-ignore Images are not known as modules
import zhFlag from "../../assets/images/markets/zh.png";
import { AppContainerContext } from "../../context/AppContext";
import { SectionName } from "../../enum/sectionName.model";
import { PanoramaEnum } from "../../enum/panorama.model";

const flagsMapper: { [key: string]: string } = {
  en:enFlag,
  de:deFlag,
  zh:zhFlag
};

const Sidebar = ({ sidebarList }: SideBarProps) => {
  const [sidebarItems, setSidebarItems] = useState<string[]>([]);
  const { isSidebarOpen, setIsSidebarOpen } = useContext(HeaderContainerContext);
  const sidebarRef = useRef(null);
  const itemId = useId();
  const { sections }: any = DataContainer.mApplicationData;
  const [markets, setMarkets] = useState<MarketsModel[]>(JSON.parse(localStorage.getItem("marketObject") as string));
  const [showAllMarkets, setShowAllMarkets] = useState(false);
  const [currentLocale, setCurrentLocale] = useState<MarketsModel>({
    id: Number(DataContainer._localeId),
    locale: localStorage.getItem("activeLocale") ?? DataContainer._locale,
    language: DataContainer._language
  });
  const localeRef = useRef(null);

  const handleOutsideClick = (ref?: React.RefObject<HTMLDivElement>, event?: Event) => {
    if (event?.target === ref?.current) {
      setIsSidebarOpen(false);
      setShowAllMarkets(false);
    }
  };
  
  const setItemFirstInList = (market: MarketsModel) => {
    const result = markets.filter((item: MarketsModel) => item.id !== market.id).reverse();
    result.unshift(market);
    localStorage.setItem("marketObject", JSON.stringify(result));
    setMarkets(result);
  };

  const getMarkets = () => {
    setMarkets([]);
    getLocales().then((markets) => {
      markets.data.data.map((item: MarketsModel) => {
        setMarkets((prev: MarketsModel[]) => [...prev, item]);
      });
    });
  };

  const onMarketClick = (item: MarketsModel) => {
    currentLocale.id !== item.id && setItemFirstInList(item);
    setShowAllMarkets((prev: boolean) => !prev);
    if (currentLocale.id !== item.id) {
      DataContainer.loadApplicationData(() => {
        setCurrentLocale({
          id: item.id,
          locale: item.locale,
          language: item.language
        });
        localStorage.setItem("activeLocale", item.locale);
        localStorage.setItem("localeID", `${item.id}`);
        localStorage.setItem("activeLanguage", item.language);
      }, item.id);
    }
  };

  const addZeroBeforeNumber = (num: number) => {
    if (num + 1 < 10) {
      return `0${num + 1}`;
    } else {
      return num + 1;
    }
  };

  const onClickSidebarElement = (key: string) => {
    history.pushState("", "", `/${key}`);
    setHotspotName(key);
    setIsSidebarOpen(false);
    setShowAllMarkets(false);
  };

  useEffect(() => {
    setPlayCameraText(DataContainer.sectionKey(
      SectionName.ACTION_KEYWORD,
      PanoramaEnum.ACTION_ROTATE_CAMERA_3D
    ));
  }, [currentLocale]);

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem("marketObject") as string)) {
      getMarkets();
    }
  }, []);

  useEffect(() => {
    setSidebarItems(sidebarList.filter((item) => !item.includes(ComponentEnum.OUT_LINK)));
    if (isSidebarOpen) {
      document.addEventListener("click", (e: Event) => {
        handleOutsideClick(sidebarRef, e);
      });
    } else {
      return document.removeEventListener("click", (e: Event) => {
        handleOutsideClick(sidebarRef, e);
      });
    }
  }, [isSidebarOpen]);

  return (
    <>
      {isSidebarOpen && (
        <div
          className={classnames(
            "w-screen h-screen absolute z-[52] top-0 flex flex-col md:flex-row transition ease-in-out overflow-hidden"
          )}
        >
          <div
            ref={sidebarRef}
            className="bg-transparent backdrop-blur-sm w-3/5 hidden md:block overflow-hidden min-h-screen top-0 h-screen bottom-0 relative"
          ></div>
          <div className="h-screen md:w-3/6 top-0 w-screen bg-black-light md:bg-gray-medium text-white relative overflow-auto">
            <div className="w-full h-20 overflow-hidden top-0 absolute bg-black-light md:bg-gray-medium md:-mb-10 flex items-center justify-between pl-8 md:pl-21 pt-8 pb-0 pr-8 md:pr-10 cursor-pointer">
              <ul className="flex overflow-x-auto">
                {markets && markets.map((item: MarketsModel) => (
                  <li
                    ref={localeRef}
                    key={item.id}
                    className={`pr-5 cursor-pointer
                      ${showAllMarkets || item.locale === currentLocale.locale ? "block" : "hidden"}`}
                    onClick={() => onMarketClick(item)}>
                    <div className="flex items-center">
                      <span
                        className={classnames(
                          "rounded-full",
                          { "border" : item.locale === currentLocale.locale },
                          "border-gray-400"
                        )}>
                        <img
                          src={flagsMapper[item.locale]}
                          alt={item.locale}
                          className="w-7 min-w-[28px] h-7 rounded-full p-1"
                        />
                      </span>
                      <p className={classnames(
                        "text-sm ml-3 font-light text-white break-normal whitespace-nowrap", 
                        {"border-b opacity-70 border-opacity-70" : item.locale === currentLocale.locale})}>
                        {MarketsEnum[item.language.toLocaleUpperCase() as keyof typeof MarketsEnum]}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
              <button
                onClick={() => setIsSidebarOpen(false)}
                className="border border-white rounded-full p-3"
              >
                {isMobile() ? Icons.bigCloseIcon : Icons.smallCloseIcon}
              </button>
            </div>
            <ul className="h-full overflow-x-hidden overflow-y-initial pt-20">

              {sidebarItems.map((name: string, index: number) => (
                <li
                  id={itemId + `${index}`}
                  key={index}
                  className="px-8 md:px-21 cursor-pointer"
                  onClick={() => onClickSidebarElement(name)}>
                  <div
                    className={classnames(
                      "flex justify-between items-end border-b border-white-medium border-opacity-10",
                      { "py-9 md:py-16": index > 0 },
                      { "pb-9 md:pb-16 pt-3 md:pt-14": index === 0 }
                    )}>
                    <div className="flex flex-col text-white-medium font-light">
                      <span className="text-[8px] md:text-xs">{addZeroBeforeNumber(index)}</span>
                      <span className="pt-2.5 text-lg md:text-xl">
                        {DataContainer.getSingelSectionValue(
                          sections,
                          ComponentEnum.HOTSPOTS_NAME,
                          name
                        )}
                      </span>
                    </div>
                    <span className="block md:hidden">{Icons.RightArrow}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
