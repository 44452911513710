import { useState } from "react";
import { useContext } from "react";
import { first } from "rxjs";
import S360View from "../../components/360View/360View";
import { AppContainerContext } from "../../context/AppContext";
import { ComponentEnum } from "../../enum/componet.model";
import { ComponentModel } from "../../interface/component.model";
import DataContainer, { getHotspotName, getLocationPathName } from "../../services/DataContainer";

const PanoramaView = () => {
  const babylon: HTMLDivElement = document.querySelector(".babylon")!;
  const [hotspotKey, setHotspotKey] = useState(getLocationPathName());
  const { product } = useContext(AppContainerContext);
  if (!product) {
    return (
      <h3 className="absolute top-1/2 left-0 right-0 ml-auto mr-auto w-max">
        Loading Panorama View
      </h3>
    );
  }

  getHotspotName()
    .pipe(first())
    .subscribe((key: string) => {
      setHotspotKey(key);
      const pathname: any = getLocationPathName();
      if(DataContainer.sectionKey(ComponentEnum.HOTSPOTS_NAME, key) !== pathname && pathname?.length > 2) {
        babylon.classList.remove("block");
        babylon.classList.add("hidden");
      } else {
        babylon.classList.remove("hidden");
        babylon.classList.add("block");
      }
    });

  return product?.component_groups[0].components.map(({ name, asset }: ComponentModel) => {
    if (name === hotspotKey) {
      return <S360View key={hotspotKey} hotspotName={hotspotKey} image_url={asset} />;
    }
  });
};

export default PanoramaView;
