import { useContext, useState } from "react";
import { Icons } from "../../constants/Icons/Icons";
import { Typography } from "../Typography/Typography";
import { HeaderContainerContext } from "../../context/HeaderContext";
import DataContainer, { getHotspotName, getLocationPathName, setHotspotName } from "../../services/DataContainer";
import { first } from "rxjs";
import { getOffsetTop, isMobile } from "../../utils/utils";
import { ComponentEnum } from "../../enum/componet.model";

const Navbar = () => {
  const { isSidebarOpen, setIsSidebarOpen } = useContext(HeaderContainerContext);
  const [pathName, setPathName] = useState(
    window.location.pathname.slice(1, window.location.pathname.length)
  );
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const canvasTop = getOffsetTop(document.getElementById("virtualShowroomApp"));
  const sidebar = document.getElementById("sidebar")!;
  if(sidebar) sidebar.style.top = `${canvasTop}px`;

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const onClickBackButton = () => {
    if(isMobile()){
      handleMouseOver();
      setTimeout(() => {
        history.pushState("", "", "/");
        setHotspotName("/");
        handleMouseOut();
      }, 1200);
    } else {
      history.pushState("", "", "/");
      setHotspotName("/");
      handleMouseOut();
    }};

  getHotspotName()
    .pipe(first())
    .subscribe((key: string) => {
      setPathName(key);
    });

  return (
    <div className="absolute z-[51] py-12 h-14 bg-transparent text-white font-bold flex justify-end items-center w-full px-10" id="sidebar">
      {pathName === "/" || pathName === "" || DataContainer.sectionKey(ComponentEnum.HOTSPOTS_NAME, getLocationPathName()) === getLocationPathName() ? (
        <button
          onClick={() => {
            setIsSidebarOpen(!isSidebarOpen);
          }}
        >
          {Icons.Menu}
        </button>
      ) : (
        <div className="w-full flex justify-start md:justify-end items-center">
          <button
            className="text-white font-bold justify-start md:justify-end items-center flex w-full"
            onClick={() => onClickBackButton()}
          >
            <Typography
              tag="p"
              className="text-sm hidden left-0 md:block font-normal text-white px-4 text-left"
            >
              Back to the<br /> showfloor
            </Typography>
            <div
              className="md:opacity-50 hover:opacity-100 w-20 hover:mt-[0.15rem]"
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
            >
              {isHovered ? Icons.BackPanoIconOpen : Icons.BackPanoIcon}
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

export default Navbar;
