import classnames from "classnames";
import { useContext, useEffect, useState } from "react";
import { Icons } from "../../../constants/Icons/Icons";
import { AppContainerContext } from "../../../context/AppContext";
import { ComponentEnum } from "../../../enum/componet.model";
import { ComponentModel } from "../../../interface/component.model";
import { HotspotModel } from "../../../interface/hotspots.model";
import DataContainer, { getLocationPathName, setHotspotName} from "../../../services/DataContainer";
import { isMobile, isTablet } from "../../../utils/utils";
import { Typography } from "../../Typography/Typography";
import { ViewDescriptionProps } from "./ViewDescriptionProps";

const ViewDescription = ({
  headline,
  content,
  link_title,
  link_url,
  imageDescription,
  showDescription,
  setShowDescription,
  innerHeight,
  content_second,
  link_title_second,
  link_url_second
}: ViewDescriptionProps) => {
  const [hotspotKey, setHotspotKey] = useState("");
  const [imageURL, setImageURL] = useState("");
  const { hotspots, product } = useContext(AppContainerContext);
  const [nextHotspotName, setNextHotspotName] = useState("");
  const { sections }: any = DataContainer.mApplicationData;
  const filteredHotspots = hotspots.filter((singelHotspot: ComponentModel) => !singelHotspot.name.includes(ComponentEnum.OUT_LINK));

  useEffect(() => {
    getPanoramaNextPreviewImage();
    if (hotspotKey.length) {
      history.pushState("", "", `/${hotspotKey}`);
      setHotspotName(hotspotKey); 
    }
  }, [hotspotKey]);

  const changePanoramaViewToNextHotspot = () => {
    filteredHotspots.forEach((hotspot: HotspotModel, index: number) => {
      if (hotspot.name === getLocationPathName()) {
        if (index + 1 <= filteredHotspots.length - 1) {
          setHotspotKey(filteredHotspots[index + 1].name);
        } else {
          setHotspotKey(filteredHotspots[0].name);
        }
      }
    });
  };

  const getPanoramaNextPreviewImage = () => {
    const components = product?.component_groups[0].components;
    components.map(({ name }: ComponentModel, index: number) => {
      if (name === getLocationPathName()) {
        if (index + 1 <= components.length - 1) {
          setImageURL(product?.component_groups[0].components[index + 1].asset);
          setNextHotspotName(DataContainer.getSingelSectionValue(sections, ComponentEnum.HOTSPOTS_NAME, product?.component_groups[0].components[index + 1].name));
        } else {
          setImageURL(product?.component_groups[0].components[1].asset);
          setNextHotspotName(DataContainer.getSingelSectionValue(sections, ComponentEnum.HOTSPOTS_NAME, product?.component_groups[0].components[1].name));
        }
      }
    });
  };

  return (
    <div
      className={classnames(
        "bg-black absolute bottom-0 z-[53] w-full lg:justify-start flex flex-col lg:flex-row ease-in-out duration-300 cursor-pointer",
        { "h-[268px] items-center justify-between": showDescription && !isMobile() },
        { "h-[360px] items-center justify-between": showDescription && isTablet() },
        { "view-description-overright items-start justify-between": showDescription && isMobile() },
        { "h-[130px] items-center justify-center": !showDescription }
      )}
      onClick={() => {
        setShowDescription(!showDescription);
      }}
    >
      <div
        className={classnames("flex flex-row-reverse items-center lg:flex-row lg:w-4/6", {
          "lg:items-start pt-4 z-[53]": showDescription
        })}
      >
        <div
          className={classnames("w-[10vw] h-full flex justify-center items-start lg:items-center", {
            "items-center": !showDescription
          })}
        >
          <div
            className={classnames("flex justify-center w-full pr-3 lg:pr-0", 
              {"items-start lg:items-center pt-3 md:pt-2": showDescription},
              {"absolute top-8" : showDescription && isMobile()})}>
            {showDescription ? Icons.DownArrow : Icons.UpArrow}
          </div>
        </div>
        <div className="flex flex-col h-full max-h-[80vh] md:max-h-[268px] overflow-y-auto w-[90vw]">
          <div className="h-auto w-full flex flex-col justify-between items-start pl-8 py-0 lg:pl-0">
            {headline && (
              <Typography tag="h3" className="md:text-xl text-white font-medium my-4 md:my-0 ease-in-out duration-300">
                {headline}
              </Typography>
            )}
            {showDescription && (
              <>
                {content && (
                  <Typography
                    className="block text-white font-light lg:text-lg md:pt-4 mb-4 ease-in-out delay-300 "
                    tag="p"
                  >
                    {content}
                  </Typography>
                )}
                {link_title && (
                  <a href={link_url} target="_blank" rel="noreferrer" className="pt-0">
                    <Typography
                      tag="h3"
                      className="text-blue-light text-md block text-left font-medium ease-in-out delay-300">
                      {link_title}
                    </Typography>
                  </a>
                )}
              </>
            )}
          </div>
          {(showDescription && content_second) && (
            <div className="h-auto w-full flex flex-col justify-between items-start pl-8 py-0 lg:pl-0">
              <Typography
                className="block text-white font-light lg:text-lg md:pt-4 mb-4"
                tag="p"
              >
                {content_second}
              </Typography>
              <a href={link_url_second} target="_blank" rel="noreferrer" className="pt-0">
                <Typography
                  tag="h3"
                  className="text-blue-light text-md block text-left font-medium">
                  {link_title_second}
                </Typography>
              </a>
            </div>
          )}
        </div>
      </div>
      <div
        className={classnames(
          "w-full bg-black lg:block relative h-[15vh] md:h-[20vh] lg:h-full right-0 cursor-pointer",
          {
            "hidden lg:block": !showDescription
          }
        )} onClick={() => changePanoramaViewToNextHotspot()}>
        <img src={imageURL} className="w-full absolute top-0 object-cover object-top next-preview-image" />
        <div className={classnames("absolute pl-16 z-20 flex flex-col w-full py-auto items-start h-full justify-center pt-8", {"md:pt-14 lg:pt-20 xl:pt-24" : showDescription})}>
          <button
            className="text-white text-md xl:text-lg align-middle">
            {imageDescription.top}
          </button>
          <button className="text-white text-lg xl:text-xl text-left align-middle" disabled={filteredHotspots.length < 1}>{nextHotspotName}</button>
        </div>
      </div>
    </div>
  );
};

export default ViewDescription;
